import React, { useContext, useEffect, useState } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { StudentAnswerContext } from '../context/StudentAnswerContext';

function CheckboxQuestion({ questionID, user, answersReadOnly, userAnswers }) {
  const { courseDesignState } = useContext(CourseDesignContext);
  const {  handleAnswerChange } = useContext(StudentAnswerContext);
  //const userAnswers = useMemo(() => studentAnswersState[user] || [], [studentAnswersState, user]);

  const [selectedAnswers, setSelectedAnswers] = useState([]);

  const question = courseDesignState.find(item => item.dataID === questionID && item.dataType === 'question');

 useEffect(() => {
  const existingAnswer = userAnswers.find(answer => answer.questionID === questionID);
  if (existingAnswer) {
    setSelectedAnswers(existingAnswer.questionAnswer ? existingAnswer.questionAnswer.split(',') : []);
  } else {
    setSelectedAnswers([]); // Clear state when no answer exists
  }
}, [userAnswers, questionID]);


  const questionText = question.dataText;
  const questionOptions = question.questionOptions ? question.questionOptions.split('|||') : [];

  const handleChange = (optionIndex) => {
    if (!answersReadOnly) {
      const optionValue = (optionIndex + 1).toString();
      let updatedSelectedOptions;

      if (selectedAnswers.includes(optionValue)) {
        updatedSelectedOptions = selectedAnswers.filter(item => item !== optionValue);
      } else {
        updatedSelectedOptions = [...selectedAnswers, optionValue];
      }

      setSelectedAnswers(updatedSelectedOptions);
      handleAnswerChange(user, questionID, updatedSelectedOptions.join(','));
    }
  };

  const getButtonClass = (index) => {
    const isSelected = selectedAnswers.includes((index + 1).toString());
    return isSelected ? "checkboxButton selected" : "checkboxButton";
  };

  const renderOption = (option, index) => {
    if (answersReadOnly) {
      return (
        <div key={index} className={getButtonClass(index)}>
          {option}
        </div>
      );
    } else {
      return (
        <label key={index} className={getButtonClass(index)}>
          <input
            type="checkbox"
            className="checkboxInput"
            name={questionID}
            checked={selectedAnswers.includes((index + 1).toString())}
            onChange={() => handleChange(index)}
          />
          {option}
        </label>
      );
    }
  };

  return (
    <div className='questionContainer'>
      <p className='questionText'>
  {questionText.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))}
</p>

      <div className='optionsContainer'>
        {questionOptions.map((option, index) => renderOption(option, index))}
      </div>
    </div>
  );
}

export default CheckboxQuestion;