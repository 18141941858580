import React, { useContext, useState, useEffect, useMemo, useRef } from 'react';
import { StudentGradesContext } from '../context/StudentGradesContext';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { debounce } from 'lodash';
import './GradingFields.css';


const GradingFields = ({ username, evaluationId, onCustomDueDateChange }) => {
    const { studentGradesState, handleGradeChange, loadingGrades } = useContext(StudentGradesContext);
   // console.log('GradingFields received studentGradesState:', studentGradesState);
const { courseDesignState, hasContradictionItems, questionByQuestionGrading, giveStudentsComments } = useContext(CourseDesignContext);
    const [localState, setLocalState] = useState({
    unresolvedContradictions: '',
    unansweredMCQs: '',
    comments: '',
    gradeAdjustment: '',
    customDueDate: '',
  });  
 const [runningAverage, setRunningAverage] = useState(null); // Track running average
  const prevRunningAverageRef = useRef(null); // Ref to track the previous running average
    const initialLoadRef = useRef(true);
  const { deleteGrades } = useContext(StudentGradesContext);
  // for the case in which there are not question by question grades
  const [manuallySetExamGrade, setManuallySetExamGrade] = useState('');

  
  
      // New state to manage visibility of fields
  const [visibleFields, setVisibleFields] = useState({
    unresolvedContradictions: false,
    unansweredMCQs: false,
    gradeAdjustment: false,
    customDueDate: false,
  });



    // dynamically set values for grading based on items with the relevant datatype in the course design state 
    
    const penaltyForUnansweredMCQ = useMemo(() => {
        const evaluation = courseDesignState.find(item => item.dataType === 'penaltyForUnansweredMCQ');
                // turn the string into a number
        return evaluation ? parseFloat(evaluation.dataText) : 5;
    }, [courseDesignState]);

        const penaltyForUnresolvedContradictions = useMemo(() => {
        const evaluation = courseDesignState.find(item => item.dataType === 'penaltyForUnresolvedContradictions');
                // turn the string into a number
        return evaluation ? parseFloat(evaluation.dataText) : 5;
    }, [courseDesignState]);
    
  const isNumericGrade = useMemo(() => {
    const gradeFormat = courseDesignState.find(item => item.dataType === 'numericVsLetterManualGrades')?.dataText;
    console.log('gradeFormat:', gradeFormat);
  return gradeFormat === 'Numeric';
}, [courseDesignState]);

// set values on initial load
    
  useEffect(() => {
  
      if (loadingGrades) return; // Wait until grades are loaded

  if (initialLoadRef.current) {
    const userGrades = studentGradesState[username] || [];
    
    const newLocalState = {
      unresolvedContradictions: userGrades.find(
        grade => grade.evaluationDataID === evaluationId && grade.gradingType === 'unresolvedContradictions'
      )?.individualGradingData || '0',
      unansweredMCQs: userGrades.find(
        grade => grade.evaluationDataID === evaluationId && grade.gradingType === 'unansweredMCQs'
      )?.individualGradingData || '0',
      comments: userGrades.find(
        grade => grade.evaluationDataID === evaluationId && grade.gradingType === 'comments'
      )?.comment || '',
      gradeAdjustment: userGrades.find(
        grade => grade.evaluationDataID === evaluationId && grade.gradingType === 'gradeAdjustment'
      )?.individualGradingData || '0',
      customDueDate: userGrades.find(
        grade => grade.evaluationDataID === evaluationId && grade.gradingType === 'customDueDate'
      )?.individualGradingData || '',
      gradingComplete: userGrades.find(
        grade => grade.evaluationDataID === evaluationId && grade.gradingType === 'gradingComplete'
      )?.individualGradingData || 'no', // Default to "no" if not found
    };

    setLocalState(newLocalState);

    // Persist default values for missing grading data (except customDueDate)
    if (!userGrades.find(g => g.evaluationDataID === evaluationId && g.gradingType === 'unresolvedContradictions')) {
      handleGradeChange(username, evaluationId, 'unresolvedContradictions', '0', '', evaluationId);
    }
    if (!userGrades.find(g => g.evaluationDataID === evaluationId && g.gradingType === 'unansweredMCQs')) {
      handleGradeChange(username, evaluationId, 'unansweredMCQs', '0', '', evaluationId);
    }
    if (!userGrades.find(g => g.evaluationDataID === evaluationId && g.gradingType === 'gradeAdjustment')) {
      handleGradeChange(username, evaluationId, 'gradeAdjustment', '0', '', evaluationId);
    }

    // Initialize examGrade state
    const examGrade = userGrades.find(
      grade => grade.evaluationDataID === evaluationId && grade.gradingType === 'examGrade'
    )?.individualGradingData || '';
    setManuallySetExamGrade(examGrade);

    // Set visibility for fields
    setVisibleFields({
      unresolvedContradictions: newLocalState.unresolvedContradictions !== '0',
      unansweredMCQs: newLocalState.unansweredMCQs !== '0',
      gradeAdjustment: newLocalState.gradeAdjustment !== '0',
      customDueDate: newLocalState.customDueDate !== '' && newLocalState.customDueDate !== '0',
    });

    initialLoadRef.current = false;
  }
}, [loadingGrades, studentGradesState, username, evaluationId]);


  // retrieve exam grade when manually set
  
 

const handleManuallySetExamGradeChange = (value) => {
  setManuallySetExamGrade(value); // Update local state without validation
};

const handleExamGradeBlur = () => {
  if (isNumericGrade) {
    const sanitizedValue = parseFloat(manuallySetExamGrade);

    // Validate the final value
    if (isNaN(sanitizedValue) || sanitizedValue < 0 || sanitizedValue > 100) {
      setManuallySetExamGrade(''); // Reset if invalid
      return;
    }

    // Update the database with the sanitized value
    handleGradeChange(username, evaluationId, 'examGrade', sanitizedValue.toString(), '', evaluationId);
  } else {
    // For letter grades, just update the database with the entered value
    handleGradeChange(username, evaluationId, 'examGrade', manuallySetExamGrade, '', evaluationId);
  }
};



 // Function to toggle field visibility
  const toggleFieldVisibility = (field) => {
    setVisibleFields(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };
    
    // Define the buttons and fields which show at the bottom
const fields = useMemo(() => {
  const hasPenaltyForUnansweredMCQ = courseDesignState.some(
    (item) => item.dataType === 'penaltyForUnansweredMCQ' && parseFloat(item.dataText) > 0
  );

  const hasGradeAdjustmentBox = courseDesignState.some(
    (item) => item.dataType === 'isThereBoxForGradeAdjustment' && item.dataText === 'Yes'
  );

  return [
    hasContradictionItems && { name: 'unresolvedContradictions', label: 'Unresolved Contradictions' },
    hasPenaltyForUnansweredMCQ && { name: 'unansweredMCQs', label: 'Unanswered MCQs' },
    hasGradeAdjustmentBox && { name: 'gradeAdjustment', label: 'Grade Adjustment' },
    { name: 'customDueDate', label: 'Custom Due Date' }, // Always included
  ].filter(Boolean); // Remove `false` or `undefined` entries
}, [courseDesignState, hasContradictionItems]);

    
// Render the toggle buttons and corresponding fields
 const renderToggleButtons = () => (
    <div className="toggle-grid">
      {fields.map(field => (
        <button
          key={field.name}
          className={`toggle-button ${visibleFields[field.name] ? 'active' : 'inactive'}`}
          onClick={() => toggleFieldVisibility(field.name)}
        >
          {field.label}
        </button>
      ))}
    </div>
  );

  // Render the fields
  const renderFields = () => (
    <div className="fields-container">
      {fields.map(field => (
        visibleFields[field.name] && (
          <div key={field.name} className="field">
            <label htmlFor={field.name}>{field.label}:</label>
            <input
              type={field.name === 'customDueDate' ? 'date' : 'number'}
              id={field.name}
              value={localState[field.name]}
              onChange={(e) => handleInputChange(field.name, e.target.value)}
              step={field.name === 'gradeAdjustment' ? '0.1' : '1'}
            />
          </div>
        )
      ))}
    </div>
  );

  // Effect to recalculate the running average when studentGradesState changes
  useEffect(() => {
    // Don't run the effect until the grades have finished loading.
  if (loadingGrades ||
    localState.gradeAdjustment === '' ||
    localState.unresolvedContradictions === '' ||
    localState.unansweredMCQs === '') return;
  // Also ensure that there is at least some grade data available.
    if (!studentGradesState[username]) return; // Exit if there are no grades for the user

    const currentGrades = studentGradesState[username];
    const questionGrades = currentGrades
  .filter((grade) => grade.gradingType === 'questionGrade' && grade.evaluationDataID === evaluationId)
  .map((grade) => parseFloat(grade.individualGradingData) || 0);


    // Calculate the average of the question grades
let newAverage = questionGrades.length > 0 
  ? Math.round((questionGrades.reduce((a, b) => a + b, 0) / questionGrades.length) * 10 * 10) / 10 
    : 0;
      
   newAverage += parseFloat(localState.gradeAdjustment) || 0;
    newAverage -= (parseFloat(localState.unresolvedContradictions) || 0) * penaltyForUnresolvedContradictions;
    newAverage -= (parseFloat(localState.unansweredMCQs) || 0) * penaltyForUnansweredMCQ;
      
    // Only update state if the new average is different from the previous one
    if (newAverage !== prevRunningAverageRef.current) {
      setRunningAverage(newAverage);
      prevRunningAverageRef.current = newAverage; // Update the previous average

      // Update state and database using the handleGradeChange function
      handleGradeChange(username, evaluationId, 'examGrade', newAverage.toString(), '', evaluationId);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentGradesState, username, evaluationId, handleGradeChange, penaltyForUnansweredMCQ, penaltyForUnresolvedContradictions,
  localState.gradeAdjustment,        // Added dependency
  localState.unresolvedContradictions, // Added dependency
    localState.unansweredMCQs,          // Added dependency
  loadingGrades
]);

    
    
// Function to handle sending data to the server


const debouncedHandleGradeChange = useMemo(() => debounce((value) => {
  handleGradeChange(username, evaluationId, 'comments', '', value, evaluationId);
}, 500), [username, evaluationId, handleGradeChange]);

const handleInputChange = (gradingType, value) => {
  setLocalState((prevState) => ({
    ...prevState,
    [gradingType]: value,
  }));
  if (gradingType === 'customDueDate') {
     deleteGrades(username, evaluationId, 'questionGrade'); // Delete grades on change of due date since answers may change
      onCustomDueDateChange(value); // Notify parent of customDueDate change
    }
  if (gradingType === 'comments') {
    debouncedHandleGradeChange(value); // Use the debounced handler
  } else {
    handleGradeChange(username, evaluationId, gradingType, value, '', evaluationId);
  }
};

useEffect(() => {
  return () => {
    debouncedHandleGradeChange.cancel(); // Clean up debounce on unmount
  };
}, [debouncedHandleGradeChange]);



  return (
    
    <div className="grading-fields">

      { questionByQuestionGrading && (
           <div className="running-grade">
        Running Grade:  {runningAverage !== null ? runningAverage.toFixed(1) : 'Calculating...'}
      </div>
      )}
      
{!questionByQuestionGrading && (
  <div
    className="exam-grade-entry"
    style={{ display: 'flex', alignItems: 'center', margin: '20px 0' }} // Inline styles added
  >
    <label
      htmlFor="examGrade"
      style={{ marginRight: '10px' }} // Add spacing between label and input
    >
      Grade:
    </label>
    <input
      type="text"
      id="examGrade"
      value={manuallySetExamGrade}
      onChange={(e) => handleManuallySetExamGradeChange(e.target.value)}
      onBlur={handleExamGradeBlur}
      maxLength={10}
    />
  </div>
)}


      {giveStudentsComments && (
        <div className="student-comments">
          <textarea
            id="comments"
            className="comments-textarea" // Add specific class for textarea
            value={localState.comments}
            onChange={(e) => handleInputChange('comments', e.target.value)}
            rows="4"
          />
        </div>
      )}

          <div className="view-toggle">
  <label className="switch">
    <input
      type="checkbox"
      checked={localState.gradingComplete === 'yes'}
      onChange={() =>
        handleInputChange(
          'gradingComplete',
          localState.gradingComplete === 'yes' ? 'no' : 'yes'
        )
      }
    />
    <span className="slider round"></span>
  </label>
  <span className="toggle-label">
    {localState.gradingComplete === 'yes'
      ? 'Grading Complete'
      : 'Grading In Progress'}
  </span>
</div>

 {renderToggleButtons()}
      {renderFields()}
    
    </div>
  );
};

export default GradingFields;