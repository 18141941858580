import React, { createContext, useState, useEffect, useMemo, useCallback, useContext } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import { CourseIDContext } from './CourseIDContext';


const CourseDesignContext = createContext();

const CourseDesignProvider = ({ children }) => {
  const [courseDesignState, setCourseDesignState] = useState([]);
const { courseID } = useContext(CourseIDContext);

  
  // stores content of thesis editor if user navigats away from it ad then back to it
    const [currentThesis, setCurrentThesis] = useState({
    id: '',
    name: '',
    logic: '',
    description: '',
  }); // Added state for thesis editor
  
const [thesisEditorIsOpen, setThesisEditorIsOpen] = useState(false); // Add isEditing state


  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  // Fetch initial state from the backend on page load and refresh course design data on change of focus
useEffect(() => {
  const fetchData = () => {
    axios.get(`${apiUrl}/api/courseDesign/${courseID}`)
      .then(response => {
        if (Array.isArray(response.data)) {
          const newState = response.data.map(item => ({
            dataType: item.dataType.toString(),
            dataID: item.dataID.toString(),
            dataIndex: item.dataIndex?.toString(),
            dataText: item.dataText?.toString(),
            questionType: item.questionType?.toString(),
            questionOptions: item.questionOptions?.toString(),
            questionLogic: item.questionLogic?.toString(),
            questionCourseDate: item.questionCourseDate?.toString(),
          }));
          setCourseDesignState(newState);
        } else {
          console.warn('Expected an array but received:', response.data);
        }
      })
      .catch(error => {
        console.error('Error fetching course design data:', error);
      });
  };

  // Fetch initial data on mount
  fetchData();

  // Add event listener for tab focus
  const handleFocus = () => {
    console.log('Tab focused, refetching course design data...');
    fetchData();
  };

  window.addEventListener('focus', handleFocus);

  return () => {
    window.removeEventListener('focus', handleFocus);
  };
}, [apiUrl, courseID]); // Dependency array remains the same



  // prepares the course design state for batch upsert ad batch removals using the two subsequent functions

    const prepareStateForUpsertToDatabase = (state) => {
  return state.map(({ dataType, dataID, dataText, dataIndex, questionType, questionOptions, questionLogic, questionCourseDate }) => ({
    dataType,
    dataID,
    dataText,
    dataIndex,
    questionType,
    questionOptions,
    questionLogic,
    questionCourseDate,
  }));
};
 

const updateDatabase = useCallback(debounce((newState) => {
  axios.post(`${apiUrl}/api/courseDesign/update/${courseID}`, { designData: newState })
    .then(response => {
     // console.log('Course design data updated:', response.data);
    })
    .catch(error => {
      console.error('Error updating course design data:', error);
    });
}, 500), [apiUrl, courseID]);

const negativeUpdateDatabase = useCallback(debounce((newState) => {
  axios.post(`${apiUrl}/api/courseDesign/remove/${courseID}`, { designData: newState })
    .then(response => {
      console.log('Course design data removed:', response.data);
    })
    .catch(error => {
      console.error('Error removing course design data:', error);
    });
}, 500), [apiUrl, courseID]);


  // Function to handle changes in the state and database
  const handleChange = (dataType, dataID, value, additionalFields = {}) => {
    //  console.trace('handleChange called:', { dataType, dataID, value });
    // console.log(`handleChange triggered: dataType=${dataType}, dataID=${dataID}, value=${value}`, additionalFields);

    setCourseDesignState((prevState) => {
        const existingEntryIndex = prevState.findIndex(item => item?.dataType === dataType && item?.dataID === dataID);
        
        if (existingEntryIndex !== -1) {
            const existingItem = prevState[existingEntryIndex];

            const isDataTextChanged = existingItem.dataText !== value?.toString();
            const areAdditionalFieldsChanged = Object.keys(additionalFields).some(
                key => existingItem[key]?.toString() !== additionalFields[key]?.toString()
            );

            if (!isDataTextChanged && !areAdditionalFieldsChanged) {
                console.log('No change detected, returning early.');
                return prevState; 
            }

            const newState = [...prevState];
            newState[existingEntryIndex] = {
                ...existingItem,
                dataText: value !== null ? value.toString() : null,
                ...additionalFields,
            };

            if (value === null) {
                newState.splice(existingEntryIndex, 1);
            }

            //console.log('State updated, triggering updateDatabase with:', newState);
            updateDatabase(newState); 
            return newState;
        } else if (value !== null) {
            const newEntry = {
                dataType,
                dataID,
                dataText: value.toString(),
                ...additionalFields,
            };
            const newState = [...prevState, newEntry];
            //console.log('New entry added, triggering updateDatabase with:', newState);
            updateDatabase(newState); 
            return newState;
        } else {
            console.log('No change needed, returning early.');
            return prevState; 
        }
    });
};

const removeFromDatabase = (dataType, dataID) => {
  console.log(`Removing: dataType=${dataType}, dataID=${dataID}`);
  axios.post(`${apiUrl}/api/courseDesign/delete/${courseID}`, { dataType, dataID })
    .then(response => {
      console.log('Course design data deleted:', response.data);

      setCourseDesignState((prevState) => {
        const newState = prevState.filter(item => !(item.dataType === dataType && item.dataID === dataID));
        console.log('Updated state after deletion:', newState);
        return newState;
      });
    })
    .catch(error => {
      console.error('Error deleting course design data:', error, dataType, dataID);
    });
};

const hasContradictionItems = useMemo(() => 
    courseDesignState.some(item => item.dataType === 'hasContradictionItems' && item.dataText === 'Yes'),
    [courseDesignState]
);
  
  const questionByQuestionGrading = useMemo(() =>
    courseDesignState.find(item => item.dataType === 'questionByQuestionGrading' && item.dataText === 'Yes'),
    [courseDesignState]
  );

  const giveStudentsComments = useMemo(() =>
    courseDesignState.find(item => item.dataType === 'giveStudentsComments' && item.dataText === 'Yes'),
    [courseDesignState]
  );





  return (
    <CourseDesignContext.Provider value={{ 
      courseDesignState,
      setCourseDesignState,
      handleChange, 
      removeFromDatabase, 
      currentThesis,
      setCurrentThesis,
      thesisEditorIsOpen,
      hasContradictionItems,
      questionByQuestionGrading,
      giveStudentsComments,
      setThesisEditorIsOpen,
      prepareStateForUpsertToDatabase,
      updateDatabase,
      negativeUpdateDatabase,
    }}>
      {children}
    </CourseDesignContext.Provider>
  );
};

export { CourseDesignContext, CourseDesignProvider };