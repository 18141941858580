// CourseDesignScheduleMaker.js

import React, { useContext, useEffect, useState } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import { generateRandomID } from '../utils/scheduleMakerUtilities';
import {
  CourseDateItem,
  TopicItem,
  SectionNameItem,
  NewSectionHeaderButton,
  NewClassDayButton,
  CancelAddNewItemButton,
  MoveToHereButton,
} from '../atoms/ScheduleMakerSubcomponents';
import './CourseDesignScheduleMaker.css';



// Import utility functions
import 
{ validateDataOnLoad }
from '../utils/scheduleMakerUtilities';

const CourseDesignScheduleMaker = () => {
  const { courseDesignState, handleChange, setCourseDesignState, updateDatabase, prepareStateForUpsertToDatabase, removeFromDatabase } = useContext(CourseDesignContext);
// a secondary state which generates objects based on the courseDesign state but does not need to interact with the server
const [scheduleMakerState, setScheduleMakerState] = useState([]);
  const [showMoveToHereButtons, setShowMoveToHereButtons] = useState(false);
  const [showAddItemButtons, setShowAddItemButtons] = useState(-1);
  const [itemToMove, setItemToMove] = useState(0);

const handleAddSectionHeader = (dataIndex) => {
    createNewSectionHeader(dataIndex);
};

  const handleCreateCourseDateAndTopic = (dataIndex) => {
    createCourseDateAndTopic(dataIndex);
  };

  //useffect which populates the second state of UI elements based on the contents of the courseDesignState
  useEffect(() => {
    // look for the highest dataIndex in the courseDesignState among the topic and sectionName items
    const maxIndex = Math.max(
      ...courseDesignState
        .filter((item) => ['topic', 'sectionName'].includes(item.dataType))
        .map((item) => parseInt(item.dataIndex))
    );
    const buttons = [];

  for (let i = 0.5; i <= maxIndex + 0.5; i += 1) {
    buttons.push(
      {
        dataType: 'newSectionHeaderButton',
        dataID: generateRandomID(),
        dataIndex: i.toString(),
        dataText: 'newSectionHeaderButton',
      },
      {
        dataType: 'newClassDayButton',
        dataID: generateRandomID(),
        dataIndex: i.toString(),
        dataText: 'newClassDayButton',
      },
      {
        dataType: 'cancelAddNewItemButton',
        dataID: generateRandomID(),
        dataIndex: i.toString(),
        dataText: 'cancelAddNewItemButton',
      },
      {
        dataType: 'moveToHereButton',
        dataID: generateRandomID(),

        dataIndex: i.toString(),
        dataText: 'moveToHereButton',
      }
    );
  }

    setScheduleMakerState(buttons);
    console.log('ScheduleMakerState:', scheduleMakerState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [courseDesignState]);

 // function which takes the current state and makes sure that it has the right number and a correct ordering or coursedates, topics, and sectionnames, and then updates both the local state and the database. will not delete items from the databvase
  const regularizeCourseSchedule = (state) => {
  const validatedState = validateDataOnLoad(state);
  setCourseDesignState(validatedState);
  const stateForDatabase = prepareStateForUpsertToDatabase(validatedState);
    updateDatabase(stateForDatabase);
};
  
  useEffect(() => {
  // runs on load to make sure data is in right form
  regularizeCourseSchedule(courseDesignState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


  // function to check if a topic has associated questions
  const checkForAssociatedQuestions = (topic) => {
    // get the topic's dataID and see if there are any items in state with the dataType of question whose questionCourseDate property matches the topic's dataID
    const associatedQuestions = courseDesignState.filter((item) => item.dataType === 'question' && item.questionCourseDate === topic.dataID);
    return associatedQuestions.length > 0;
  };

  
  // Extract and sort the items
  const items = courseDesignState
    .filter((item) =>
      ['topic', 'courseDate', 'sectionName', 'newSectionHeaderButton', 'newClassDayButton', 'cancelAddNewItemButton', 'moveToHereButton'].includes(item.dataType)
  )
    // add to the collection all of the items in the scheduleMakerState
    .concat(scheduleMakerState)
    // assign the hasAssociatedQuestions property to each topic item which has associated questions
    .map((item) => {
    if (item.dataType === 'topic') {
      return {
        ...item,
        hasAssociatedQuestions: checkForAssociatedQuestions(item),
      };
    }
    return item;
    })
    .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex));

  // Group items by dataIndex
  const groupedItems = {};
  items.forEach((item) => {
    const index = item.dataIndex;
    if (!groupedItems[index]) {
      groupedItems[index] = {};
    }
    groupedItems[index][item.dataType] = item;
  });

  // Prepare data indices for iteration
  const dataIndices = Object.keys(groupedItems).sort(
    (a, b) => parseInt(a) - parseInt(b)
  );




 const moveItem = (targetDataIndex) => {
  const targetIndex = parseFloat(targetDataIndex);

  // Make a copy of the state on which we can then perform various operations
  const revisedState = courseDesignState.map((item) => ({ ...item }));

  const targetItem = revisedState.find((item) => item.dataType === 'topic' && item.dataIndex === itemToMove.toString()) || revisedState.find((item) => item.dataType === 'sectionName' && item.dataIndex === itemToMove.toString());

  if (!targetItem) {
    console.error(`No item found with dataIndex ${itemToMove}`);
    return;
  }

  if (itemToMove < targetIndex) {
    targetItem.dataIndex = (targetIndex + 0.5).toString();

    revisedState.forEach((item) => {
      if (['topic', 'sectionName'].includes(item.dataType) && parseFloat(item.dataIndex) > itemToMove && parseFloat(item.dataIndex) <= targetIndex) {
        item.dataIndex = (parseFloat(item.dataIndex) - 1).toString();
      }
    });
  } else {
    targetItem.dataIndex = (targetIndex - 0.5).toString();

    revisedState.forEach((item) => {
      if (['topic', 'sectionName'].includes(item.dataType) && parseFloat(item.dataIndex) < itemToMove && parseFloat(item.dataIndex) >= targetIndex) {
        item.dataIndex = (parseFloat(item.dataIndex) + 1).toString();
      }
    });
  }

  // Update the real state with the revised state


  regularizeCourseSchedule(revisedState);
  toggleMoveToHereButtons();
 };
  
  const deleteItem = (item) => {
  const { dataType, dataIndex, dataID } = item;

  if (dataType === 'topic' && checkForAssociatedQuestions(item)) {
    const confirmDelete = window.confirm(
      'This topic has associated questions. Are you sure you want to delete it? This will also delete the questions. You can change the name of the topic, or change its location in the course, without affecting the associated questions. You can also move the questions to a different topic inside the questions module.'
    );
    if (!confirmDelete) return;
  }

  // Create a revised state excluding the item
  let revisedState = courseDesignState.filter((stateItem) => stateItem.dataID !== dataID);

  // If the item is a topic, also find and remove its associated courseDate
 if (dataType === 'topic') {
    const courseDateItem = courseDesignState.find(
      (item) => item.dataType === 'courseDate' && item.dataIndex === dataIndex
    );
    if (courseDateItem) {
      revisedState = revisedState.filter((stateItem) => stateItem.dataID !== courseDateItem.dataID);
      
      // Remove courseDate from the database
      removeFromDatabase(courseDateItem.dataType, courseDateItem.dataID);
    }
  }

  // Update both local state and database
  setCourseDesignState(revisedState);
  removeFromDatabase(dataType, dataID);
};


  const hideAddNewItemButtons = () => {
    setShowAddItemButtons(-1);
  };

const createNewSectionHeader = async (dataIndex) => {
  // Create a new section header item
  const newSectionHeader = {
    dataType: 'sectionName',
    dataID: generateRandomID(),
    // set dataIndex to 0.5 more
    dataIndex: (parseInt(dataIndex) + 1).toString(),
    dataText: 'New Section',
  };
  // Find every item in state of the type courseDate or topic or sectionName with a dataIndex greater than or equal to the new section header's dataIndex and increase their dataIndex by 1
  const revisedState = courseDesignState.map((item) => {
    if (['courseDate', 'topic', 'sectionName'].includes(item.dataType) && parseInt(item.dataIndex) >= parseInt(newSectionHeader.dataIndex)) {
      return {
        ...item,
        dataIndex: (parseInt(item.dataIndex) + 1).toString(),
      };
    }
    return item;
  });
  // Add the new section header to the state
  revisedState.push(newSectionHeader);
  // Regularize the course schedule and upload
  regularizeCourseSchedule(revisedState);
  // Get rid of all popup buttons after a delay of 0.5 seconds
  hideAddNewItemButtons();
};
      
  const createCourseDateAndTopic = async (dataIndex) => {
    // look in state for the courseDate item with the same dataIndex or, if there is not one, the courseDate item with the next highest dataIndex, and find its dataTexT and questionLogic properties
  // Find the highest existing courseDate dataIndex
const lastCourseDate = courseDesignState
  .filter(item => item.dataType === 'courseDate')
  .sort((a, b) => parseInt(a.dataIndex) - parseInt(b.dataIndex))
  .pop(); // Get the last courseDate

const highestIndex = lastCourseDate ? parseInt(lastCourseDate.dataIndex) : -1; 

let courseDateItem;

// If adding after the last course date, set a new date
if (parseInt(dataIndex) > highestIndex) {
  const newDate = new Date(lastCourseDate?.questionLogic || new Date());
  newDate.setDate(newDate.getDate() + 1); // Add one day

  // Format the new date properly
  const formattedDate = newDate.toISOString().split('T')[0]; // yyyy-mm-dd
  const formattedDisplayDate = newDate.toLocaleDateString('en-US', { weekday: 'short', month: 'numeric', day: 'numeric' });

  courseDateItem = { dataText: formattedDisplayDate, questionLogic: formattedDate };
} else {
  // If inserting within existing dates, use the same logic as before
  courseDateItem = courseDesignState.find(
    (item) => item.dataType === 'courseDate' && item.dataIndex === dataIndex
  ) || courseDesignState.find(
    (item) => item.dataType === 'courseDate' && parseInt(item.dataIndex) > parseInt(dataIndex)
  ) || {
    dataText: 'Set date',
    questionLogic: new Date().toISOString().split('T')[0], // Default to today's date
  };
}


   
  // Create a new course date item
  const newCourseDate = {
    dataType: 'courseDate',
    dataID: generateRandomID(),
    dataIndex: (parseInt(dataIndex) + 1).toString(),
    dataText: courseDateItem?.dataText,
    questionLogic: courseDateItem?.questionLogic,
  };

  // Create a new topic item
  const newTopic = {
    dataType: 'topic',
    dataID: generateRandomID(),
    dataIndex: (parseInt(dataIndex) + 1).toString(),
    dataText: 'New Topic',
  };

  // Find every item in state of the type courseDate or topic or sectionName with a dataIndex greater than or equal to the new items' dataIndex and increase their dataIndex by 2
  const revisedState = courseDesignState.map((item) => {
    if (['courseDate', 'topic', 'sectionName'].includes(item.dataType) && parseInt(item.dataIndex) >= parseInt(newCourseDate.dataIndex)) {
      return {
        ...item,
        dataIndex: (parseInt(item.dataIndex) + 2).toString(),
      };
    }
    return item;
  });

  // Add the new course date and topic to the state
  revisedState.push(newCourseDate);
  revisedState.push(newTopic);

  // Regularize the course schedule and upload
  regularizeCourseSchedule(revisedState);

   hideAddNewItemButtons();
};
  
  //function to toggle the state that controls the visibility of the move to here buttons
  const toggleMoveToHereButtons = () => {
    setShowMoveToHereButtons(!showMoveToHereButtons);
    console.log('ShowMoveToHereButtons:', showMoveToHereButtons);
  };

  const movementButtonAction = (dataIndex) => {
    setItemToMove(dataIndex);
    console.log('ItemToMove:', itemToMove);
    toggleMoveToHereButtons();
  };


  const handleShowAddItemButtons = (dataIndex) => {
    setShowAddItemButtons(dataIndex);
    console.log('ShowAddItemButtons:', showAddItemButtons);
};
  
  

  // Render the component
return (
  <div className="course-design-schedule-maker">
    {/* Top Text */}
    <p className="top-text">
      Click on any text item or date to edit it; use the buttons at the right to rearrange or add items. Note that deleting a course day will also delete any questions that you had associated with that day.
    </p>

    <div className="schedule">
      {dataIndices.map((dataIndex, idx) => {
        const group = groupedItems[dataIndex];
        const currentIndex = parseFloat(dataIndex);
        const totalItems = dataIndices.length;

        return (
          <div key={`fragment-${dataIndex}`} className="schedule-row">
            {/* Section Name Row */}
            {group.sectionName && (
              <SectionNameItem
          item={group.sectionName}
          onChange={handleChange}
          removeItem={deleteItem}
         movementButtonAction={movementButtonAction}
          handleShowAddItemButtons={handleShowAddItemButtons} // Pass addNewItem here
          currentIndex={currentIndex}
          totalItems={totalItems}
              />
            )}

             {(group.newSectionHeaderButton || group.newClassDayButton || group.cancelAddNewItemButton) && (
        <div className="new-buttons-row">
          {group.newSectionHeaderButton && (
            (parseFloat(dataIndex) === showAddItemButtons + 0.5 || parseFloat(dataIndex) === showAddItemButtons - 0.5) && (
              <NewSectionHeaderButton
                dataText={group.newSectionHeaderButton.dataText}
                onClick={() => handleAddSectionHeader(dataIndex)}
              />
            )
          )}
          {group.newClassDayButton && (
            (parseFloat(dataIndex) === showAddItemButtons + 0.5 || parseFloat(dataIndex) === showAddItemButtons - 0.5) && (
              <NewClassDayButton
                dataText={group.newClassDayButton.dataText}
                onClick={() => handleCreateCourseDateAndTopic(dataIndex)}
              />
            )
          )}
          {group.cancelAddNewItemButton && (
            parseFloat(dataIndex) === showAddItemButtons - 0.5 && (
              <CancelAddNewItemButton
                onClick={hideAddNewItemButtons}
              />
            )
          )}
        </div>
      )}



            {/* Topic and CourseDate Row */}
            {(group.topic || group.courseDate) && (
              <>
          {group.courseDate && (
            <CourseDateItem
              item={group.courseDate}
              onChange={handleChange}
              regularizeCourseSchedule={regularizeCourseSchedule}
            />
          )}
          {group.topic && (
            <TopicItem
              item={group.topic}
              onChange={handleChange}
              removeItem={deleteItem}
              handleShowAddItemButtons={handleShowAddItemButtons} // Pass addNewItem here
              currentIndex={currentIndex}
              totalItems={totalItems}
                    hasAssociatedQuestions={group.topic.hasAssociatedQuestions || false}
            movementButtonAction={movementButtonAction}
            />
          )}
              </>
            )}

          {group.moveToHereButton && (
  <MoveToHereButton
                item={group.moveToHereButton}
                show={showMoveToHereButtons}
    onClick={() => moveItem(dataIndex)}
  />
)}

          </div>
        );
      })}
    </div>
  </div>
);
}


export default CourseDesignScheduleMaker;
