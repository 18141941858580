import React, { useState, useContext } from 'react';
import axios from 'axios';
import { CourseIDContext } from '../context/CourseIDContext';

const PlagiarismCheck = () => {
    const [nValue, setNValue] = useState(8);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState([]);
    const [error, setError] = useState(null);
    
    const { courseID } = useContext(CourseIDContext);

    const handleSearch = async () => {
        setLoading(true);
        setError(null);
        setResults([]);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_API_BASE_URL}/api/plagiarism/${courseID}?n=${nValue}`
            );
            setResults(response.data.flaggedNgrams);
        } catch (err) {
            console.error(err);
            setError('Error fetching plagiarism results');
        } finally {
            setLoading(false);
        }
    };
    
    // Allow hitting Enter in the input field to trigger the search
    const handleSubmit = (e) => {
        e.preventDefault();
        handleSearch();
    };

    return (
     <div style={{ padding: '20px' }}>
      <h3>
       [in progress]
            </h3>
            </div>
);

}
    
  export default PlagiarismCheck;


  /*return (
    <div style={{ padding: '20px' }}>
      <h3>
        N-gram search: search for matching series of n words from distinct current
        students.
      </h3>
      <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
  <input
    type="number"
    value={nValue}
    onChange={(e) => setNValue(e.target.value)}
    min="1"
  />
  <button type="submit" style={{ marginLeft: '10px' }}>Search</button>
</form>

      {loading && (
        <div>
          <span>Loading...</span>
        </div>
      )}
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {results.length > 0 && (
        <div
          style={{
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            padding: '10px',
            marginTop: '20px',
          }}
        >
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th
                  style={{
                    textAlign: 'left',
                    padding: '8px',
                    borderBottom: '1px solid #ddd',
                  }}
                >
                  Users
                </th>
                <th
                  style={{
                    textAlign: 'left',
                    padding: '8px',
                    borderBottom: '1px solid #ddd',
                  }}
                >
                  Matching text
                </th>
              </tr>
            </thead>
            <tbody>
              {results.map((item, index) => (
                <tr key={index}>
                  <td
                    style={{
                      padding: '8px',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    {item.usernames.join(', ')}
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      borderBottom: '1px solid #ddd',
                    }}
                  >
                    {item.ngram}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!loading && results.length === 0 && !error && (
        <div>No results to display.</div>
      )}
    </div>
  ); 
};*/


