import React, { useContext, useState, useEffect } from 'react';
import { StudentAnswerContext } from '../context/StudentAnswerContext';
import { CourseDesignContext } from '../context/CourseDesignContext';
import AnswerTextArea from '../atoms/AnswerTextArea';
import LongAnswerGradeBox from '../atoms/LongAnswerGradeBox';  // Importing LongAnswerGradeBox
import LongAnswerGradeDisplay from '../atoms/LongAnswerGradeDisplay';  // Importing LongAnswerGradeDisplay

function LongAnswerQuestion({ questionID, username, answersReadOnly, gradeEdit, gradeDisplay, evaluationID, userAnswers }) {
  const { courseDesignState } = useContext(CourseDesignContext);
  const { handleAnswerChange } = useContext(StudentAnswerContext);
 // const studentAnswers = useMemo(() => studentAnswersState[username] || [], [studentAnswersState, username]);

  const [localAnswer, setLocalAnswer] = useState('');
 // console.log('student answers state:', studentAnswersState);

  const question = courseDesignState.find(item => item.dataID === questionID && item.dataType === 'question');

  useEffect(() => {
    const existingAnswer = userAnswers.find(answer => answer.questionID === questionID);
    if (existingAnswer) {
      setLocalAnswer(existingAnswer.questionEssayAnswer || '');
    }
  }, [userAnswers, questionID]);

  

 const handleChange = (newAnswer) => {
  setLocalAnswer(newAnswer);
  handleAnswerChange(username, questionID, newAnswer, true); // Direct call without debouncing
};


  if (!question) {
    return <div>Question not found</div>;
  }

  const questionText = question.dataText;

  return (
    <div className='questionContainer'>
      <p className='questionText longAnswerQuestionText'>
  {questionText.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))}
</p>

      
      {answersReadOnly ? (
        <div className='longAnswerTextForPDF'>
          {localAnswer}
        </div>
      ) : (
        <AnswerTextArea 
          columnName={questionID}
          initialAnswer={localAnswer}
          onChange={handleChange}
        />
      )}
      {gradeEdit && <LongAnswerGradeBox questionID={questionID} evaluationID={evaluationID} username={username} />}
      {gradeDisplay && <LongAnswerGradeDisplay questionID={questionID} evaluationID={evaluationID} username={username} />}
    </div>
  );
}

export default LongAnswerQuestion;