import React, { useState, useEffect, useContext } from 'react';
import { CourseDesignContext } from '../context/CourseDesignContext';
import GradingStudentList from '../molecules/GradingStudentList';
import GradingFormat from '../molecules/GradingFormat';
import GradingStudentHistory from '../molecules/GradingStudentHistory';
//import TAStudentAssignments from '../molecules/TAStudentAssignments';
import HandBackAssignments from '../atoms/HandBackAssignments';
import StudentWordCountRanking from '../molecules/StudentWordCountRanking';
import GradingStatistics from '../molecules/GradingStatistics';
import PlagiarismCheck from '../atoms/PlagiarismCheck';

const GradingModule = () => {
  const { courseDesignState, handleChange } = useContext(CourseDesignContext);
  const [activeComponent, setActiveComponent] = useState('GradingStudentList');

  useEffect(() => {
    const lastVisited = courseDesignState.find(item => item?.dataType === 'gradingModuleLastVisited')?.dataText;
    if (lastVisited) {
      setActiveComponent(lastVisited);
    }
  }, [courseDesignState]);

useEffect(() => {
    const contentContainer = document.querySelector('.subcomponent-content');
    if (contentContainer) {
        contentContainer.scrollTop = 0;
    }
}, [activeComponent]);


  const handleMenuChange = (menuName) => {
    setActiveComponent(menuName);
    handleChange('gradingModuleLastVisited', '0', menuName);
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'GradingStudentList':
        return <GradingStudentList />;
      case 'GradingStatistics':
        return <GradingStatistics />;
      case 'GradingFormat':
        return <GradingFormat />;
      case 'GradingStudentHistory':
        return <GradingStudentHistory />;
      case 'WordCounts':
        return <StudentWordCountRanking />;
      case 'HandBackAssignments':
        return <HandBackAssignments />;
      case 'PlagiarismCheck':
        return <PlagiarismCheck />;
      default:
        return <GradingFormat />;
    }
  };

  return (
    <div className="subcomponent-wrapper">
      <div className="subcomponent-top-menu">
        
          <button
          className={`menu-button ${activeComponent === 'GradingFormat' ? 'active' : ''}`}
          onClick={() => handleMenuChange('GradingFormat')}
        >
 Grading Setup
        </button>
        <button
          className={`menu-button ${activeComponent === 'GradingStudentList' ? 'active' : ''}`}
          onClick={() => handleMenuChange('GradingStudentList')}
        >
          Student List
        </button>
        <button
          className={`menu-button ${activeComponent === 'GradingStatistics' ? 'active' : ''}`}
          onClick={() => handleMenuChange('GradingStatistics')}
        >
          Grading Statistics
        </button>
      
         
        <button
          className={`menu-button ${activeComponent === 'GradingStudentHistory' ? 'active' : ''}`}
          onClick={() => handleMenuChange('GradingStudentHistory')}
        >
          Student History
        </button>
        <button
          className={`menu-button ${activeComponent === 'WordCounts' ? 'active' : ''}`}
          onClick={() => handleMenuChange('WordCounts')}
        >
          Word counts
        </button>
        <button
          className={`menu-button ${activeComponent === 'HandBackAssignments' ? 'active' : ''}`}
          onClick={() => handleMenuChange('HandBackAssignments')}
        >
          Hand back assignments
        </button>
         <button
          className={`menu-button ${activeComponent === 'PlagiarismCheck' ? 'active' : ''}`}
          onClick={() => handleMenuChange('PlagiarismCheck')}
        >
          Plagiarism check
        </button>
      </div>
      <div className="subcomponent-content">
        {renderComponent()}
      </div>
    </div>
  );
};

export default GradingModule;