import React, { useContext, useEffect } from 'react';
import QAndA from '../organisms/QAndA';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';


const PDFDisplay = () => {
  const { username } = useContext(AuthContext);
  console.log('Display for PDF:', username);

useEffect(() => {
  const sendHtmlToPuppeteer = async () => {


    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    setTimeout(async () => {
      // Select only the QAndA content, stripping out css from other front end files
      const qAndAContent = document.getElementById('puppeteer-content').outerHTML;

      // Inject Puppeteer-specific CSS
      const puppeteerCSS = `
        <base href="${process.env.REACT_APP_API_BASE_URL}/">
        <style>
        @media print {
      *, *::before, *::after {
        box-sizing: border-box;
      }

   


      .myPhiHeader {
        font-family: 'Sacramento-Regular', cursive !important;
        width: 7in;
        color: #4281a4;
        text-align: left;
        font-size: 55px;
        margin-left: 0;
      }

      .QAndASectionHeader {
      width: 7in;
        background-color: #fe938c;
        text-align: center;
        color: white;
        font-family: 'AlegreyaSC-Medium', cursive !important;
        padding: 10px;
        font-size: 21px;
        border-radius: 0.1in;
        margin-left: 0;
    
      }

      .longAnswerTextForPDF {
        page-break-inside: auto;
        font-family: 'CourierPrime-Regular' !important;
        font-size: 13px !important;
        width: 100%;
        background-color: #EEE2DE;
        padding: 0.2in;
        left: 0.1in;
        text-align: left;
        white-space: pre-wrap;
        margin-top: 0;
        border-bottom-left-radius: 0.1in;
        border-bottom-right-radius: 0.1in;
      }

      .radioButton, .checkboxButton {
        font-family: 'CourierPrime-Regular', mono !important;
        font-size: 12px !important;
        border: 1px solid #ccc;
        border-top-color: #f0f0f0;
        border-left-color: #f0f0f0;
        border-right-color: #a0a0a0;
        border-bottom-color: #a0a0a0;
        box-shadow: none !important;
          position: relative;
    padding: 10px 20px;
    display: inline-flex; /* Changed from inline-block to flex */
    align-items: center; /* Aligns items vertically in the center */
    justify-content: center; /* Centers content horizontally */
    margin-right: 10px;
    margin-bottom: 10px;
    max-width: 160px;
    background-color: white;
    border-radius: 1px;
      }

      .radioButton.selected, .checkboxButton.selected {
    background-color: #fdd692;
}

      .optionsContainer {
        margin-top: 0.1in;
        width: 100%;
        outline: none !important;
        border: none !important;
      }

      .questionContainer {
      width: 7in;
        box-shadow: none !important;
        background-color: transparent;
        outline: none !important;
        border: none !important;
        margin-top: 0.1in;
        margin-bottom: 0.1in;
         display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
      }



      .questionText.longAnswerQuestionText {
        border-top-left-radius: 0.1in;
        border-top-right-radius: 0.1in;
        border-bottom-left-radius: 0in;
border-bottom-right-radius: 0in;
      }

      .questionText:not(.longAnswerQuestionText) {
       border-top-left-radius: 0.1in;
        border-top-right-radius: 0.1in;
        border-bottom-left-radius: 0.1in;
border-bottom-right-radius: 0.1in;
    }


      .questionText {
        background-color: #4281a4;
        page-break-inside: avoid;
        font-family: 'Alegreya-Medium', sans-serif;
        color: white;
        width: 100%;
        break-after: avoid;
        padding-left: 0.5in;
        padding-right: 0.5in;
        padding-top: 0.2in;
        padding-bottom: 0.2in;
        font-size: 14px !important;
        box-shadow: none;
        margin-top: 0;
        margin-bottom: 0;
        outline: none !important;
        border: none !important;
        text-align: center;
      }

      

      .contradictionContainer .questionText {
        background-color: #ce4a4a;
      }
    }
        </style>
      `;

      // Construct the styled HTML
      const styledHtml = `
        <!DOCTYPE html>
        <html>
        <head>
          ${puppeteerCSS}
        </head>
        <body>
          ${qAndAContent}
        </body>
        </html>
      `;

      // Log the HTML being sent to the backend
      console.log('HTML sent to Puppeteer:', styledHtml);

      try {
        const response = await axios.post(
          `${apiUrl}/api/generate-pdf`,
          { html: styledHtml },
          { responseType: 'arraybuffer' }
        );

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = 'My Philosophy.pdf';
        document.body.appendChild(link);
        link.click();
     //   document.body.removeChild(link);

        window.close();
      } catch (error) {
        console.error('Error generating PDF:', error);
      }
    }, 2000);
  };

  sendHtmlToPuppeteer();
}, []);


return (
  <div id="puppeteer-content">
    <QAndA
      username={username}
      sectionsToShow={'all'}
      answersReadOnly={true}
      showSectionHeaders={true}
    />
  </div>
);

};

export default PDFDisplay;
