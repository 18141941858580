import React, { useState, useEffect, useContext } from 'react';
import MainMenu from '../atoms/MainMenu';
import LoginStatus from '../atoms/LoginStatus';
import CourseDescription from './CourseDescription';
import Syllabus from './Syllabus';
import MyPhi from './MyPhi';
import LoginPage from './NewLoginPage';
import { AuthContext } from '../context/AuthContext';
import { CourseIDContext } from '../context/CourseIDContext';
import { fetchAllCourseDesignData } from '../utils/serverFetchAndAddUtilities'; // adjust path as needed

const StudentView = () => {
  const [activePage, setActivePage] = useState('description');
  const [lastVisitedPage, setLastVisitedPage] = useState('description');
  const { isLoggedIn } = useContext(AuthContext);
 // console.log(isLoggedIn);
  const { courseID } = useContext(CourseIDContext);
  //es-lint-disable-next-line
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 769);
  const [isSmallScreenLocal, setIsSmallScreenLocal] = useState(window.innerWidth < 600);
  const [showDescriptionButton, setShowDescriptionButton] = useState(true);
  const [showSyllabusButton, setShowSyllabusButton] = useState(true);
  const [courseTitle, setCourseTitle] = useState(null);
  const { logout } = useContext(AuthContext); // Get logout function from context


  // log out after 30 minutes of inactivity
  useEffect(() => {
  let logoutTimer;

  const resetTimer = () => {
    clearTimeout(logoutTimer);
    logoutTimer = setTimeout(() => {
      logout(); // Auto-logout after 72 hours
    }, 72 * 60 * 60 * 1000); // 72 hours in milliseconds
  };

  // Reset timer on user interaction
  window.addEventListener('mousemove', resetTimer);
  window.addEventListener('keydown', resetTimer);
  window.addEventListener('click', resetTimer);
  window.addEventListener('scroll', resetTimer);

  resetTimer(); // Start the timer

  return () => {
    clearTimeout(logoutTimer);
    window.removeEventListener('mousemove', resetTimer);
    window.removeEventListener('keydown', resetTimer);
    window.removeEventListener('click', resetTimer);
    window.removeEventListener('scroll', resetTimer);
  };
}, [logout]);


  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1300);
      setIsSmallScreenLocal(window.innerWidth <= 600);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

// determines what page to show by default based on whether the course has a course description or syllabus

 useEffect(() => {
  const fetchCourseData = async () => {
    try {
      const data = await fetchAllCourseDesignData(courseID);
      const hasSyllabus = data.find(item => item.dataType === 'hasSyllabus')?.dataText === 'No';
      const hasCourseDescription = data.find(item => item.dataType === 'hasCourseDescription')?.dataText === 'No';
      const title = data.find(item => item.dataType === 'courseTitle')?.dataText;

      if (hasSyllabus && hasCourseDescription && title) {
        setCourseTitle(title);
        setActivePage('myphi'); // Set active page to 'myphi' if both are 'No'
      } else {
        setShowSyllabusButton(!hasSyllabus);
        setShowDescriptionButton(!hasCourseDescription);

        // Set active page based on available buttons
        if (hasCourseDescription && !hasSyllabus) {
          setActivePage('syllabus');
        } else if (hasSyllabus && !hasCourseDescription) {
          setActivePage('description');
        }
      }
    } catch (error) {
      console.error('Failed to fetch course design data:', error);
    }
  };
  fetchCourseData();
}, [courseID]);

  const handleMenuSelect = (selectedPage) => {
    if (selectedPage !== 'myphi') {
      setLastVisitedPage(selectedPage);
    }
    setActivePage(selectedPage);
  };

  const handleReturnToLastVisited = () => setActivePage(lastVisitedPage);

  const appStyle = {
  backgroundColor: 
    activePage === 'myphi' ? '#fff2f2' : 
    activePage === 'syllabus' ? 'var(--color2verylight)' : 
    'initial',

  minHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  transition: 'background-color 0.6s ease-in-out', // Add transition here
};


  const renderContent = () => {
    switch (activePage) {
      case 'description':
        return <CourseDescription />;
      case 'syllabus':
        return <Syllabus />;
      case 'myphi':
        return isLoggedIn ? <MyPhi isActive={activePage === 'myphi'} onReturnToLastVisitedPage={handleReturnToLastVisited} /> : <LoginPage onLoginSuccess={() => setActivePage('myphi')} />;
      default:
        return <CourseDescription />;
    }
  };

  return (
    <div className="student-view-container" style={appStyle}>
      <div className="top-menu-container">
        <MainMenu
          isSmallScreen={isSmallScreenLocal}
          onMenuSelect={handleMenuSelect}
          showDescriptionButton={showDescriptionButton}
          showSyllabusButton={showSyllabusButton}
          courseTitle={courseTitle}
        />
      </div>
      <div className="container-below-top-nav">
        <div className="content">
          {renderContent()}
        </div>
        <LoginStatus />
      </div>
    </div>
  );
};

export default StudentView;
