import React, { useContext, useState, useEffect } from 'react';
import { useSearchParams, Navigate } from 'react-router-dom';
import TAStudentAssignments from '../molecules/TAStudentAssignments';
import GradingStudentList from '../molecules/GradingStudentList';
import GradingStatistics from '../molecules/GradingStatistics';
import GradingStudentHistory from '../molecules/GradingStudentHistory';
import StudentWordCountRanking from '../molecules/StudentWordCountRanking';
import GradingInterface from '../molecules/GradingInterface';
import { AuthContext } from '../context/AuthContext';
import { CourseIDContext } from '../context/CourseIDContext';
import './GradingPage.css';
import LoginPage from './NewLoginPage';

const GradingPage = () => {
  const [searchParams] = useSearchParams();
  const evaluationId = searchParams.get('evaluationId');
  const gradingUsername = searchParams.get('username');
  
  // Compute a flag but don't return early yet
  const isNewTab = evaluationId && gradingUsername;
  
  const { isLoggedIn } = useContext(AuthContext);
  const { courseID } = useContext(CourseIDContext);
  const [status, setStatus] = useState(null);
  const [activeComponent, setActiveComponent] = useState(
    localStorage.getItem('lastGradingTab') || 'taStudentAssignments'
  );

  // Fetch the user's status if logged in
  useEffect(() => {
    if (isLoggedIn) {
      const fetchStatus = async () => {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const username = sessionStorage.getItem('username'); // Assuming username is stored in sessionStorage
        try {
          const response = await fetch(`${apiUrl}/api/authenticate/status/${courseID}/${username}`);
          if (response.ok) {
            const { status } = await response.json();
            setStatus(status?.toLowerCase());
          } else {
            console.error('Failed to fetch status.');
            setStatus('unauthorized');
          }
        } catch (error) {
          console.error('Error fetching status:', error);
          setStatus('error');
        }
      };

      fetchStatus();
    }
  }, [isLoggedIn, courseID]);

  const handleMenuChange = (menuName) => {
    setActiveComponent(menuName);
    localStorage.setItem('lastGradingTab', menuName); // Save the last visited tab
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case 'taStudentAssignments':
        return <TAStudentAssignments />;
      case 'studentList':
        return <GradingStudentList />;
      case 'statistics':
        return <GradingStatistics />;
      case 'history':
        return <GradingStudentHistory />;
      case 'wordCounts':
        return <StudentWordCountRanking />;
      default:
        return <TAStudentAssignments />;
    }
  };

  if (!isLoggedIn) {
    return <LoginPage onLoginSuccess={() => console.log('Logged in')} />;
  }

  // Redirect unauthorized users
  if (isLoggedIn && status && status !== 'instructor' && status !== 'ta') {
    return <Navigate to={`/${courseID}/`} />;
  }

  // Now conditionally return if in new tab mode, after all hooks are called
  if (isNewTab) {
    const handleReturn = () => window.close();
    return (
      <GradingInterface
        evaluationId={evaluationId}
        username={gradingUsername}
        onReturn={handleReturn}
      />
    );
  }

  // Render page for authorized users in the normal case
  return (
    <div className="gradingPageContainer">
      <nav>
        <button
          className={`menu-button ${activeComponent === 'taStudentAssignments' ? 'active' : ''}`}
          onClick={() => handleMenuChange('taStudentAssignments')}
        >
          TA/Student Assignments
        </button>
        <button
          className={`menu-button ${activeComponent === 'studentList' ? 'active' : ''}`}
          onClick={() => handleMenuChange('studentList')}
        >
          Student List
        </button>
        <button
          className={`menu-button ${activeComponent === 'statistics' ? 'active' : ''}`}
          onClick={() => handleMenuChange('statistics')}
        >
          Statistics
        </button>
        <button
          className={`menu-button ${activeComponent === 'history' ? 'active' : ''}`}
          onClick={() => handleMenuChange('history')}
        >
          History
        </button>
        <button
          className={`menu-button ${activeComponent === 'wordCounts' ? 'active' : ''}`}
          onClick={() => handleMenuChange('wordCounts')}
        >
          Word Counts
        </button>
      </nav>
      <div>{renderComponent()}</div>
    </div>
  );
};

export default GradingPage;
